import gql from 'graphql-tag';

const GET_LIST_ROOM_TYPES = gql`
{
  type
  updated_at
  name
  max_occupancy
  max_children
  max_adults
  id
  status
  e_room_type_status {
    id
    comment
  }
  e_room_type {
    comment
    id
  }
  room_type_beds {
    id
    length
    width
    room_type_id
    qty
    mattress_type
    bed_size
  }
  rooms (order_by: {name: asc}){
    created_at
    floor
    id
    name
    e_room_status {
      comment
      id
    }
    e_room_clean_status {
      comment
      id
    }
    room_type_id
    status
    clean_status
    updated_at
  }
  area
  created_at
  default_occupancy
  description,
  room_facilities,
  bathroom_facilities,
  view_facilities,
  bedroom_facilities,
  entertainment_facilities,
  kitchen_facilities,
  outdoor_facilities,
  floor_facilities,
  images_url,
  room_type_rates {
    created_at
    id
    price
    room_rate_id
    room_type_id
    additional_charge_per_adult
    updated_at

    room_type_rate_adjustments {
	id
	value
	date
    }

    room_type_rate_adjustments {
	id
	value
	date
    }
    room_rate {
      cancellation_policy_id
      created_at
      id
      updated_at
      meal_id
      name
      status
      meal {
        created_at
        id
        name
        price
        service_charge
        updated_at
      }
      cancellation_policy {
        after_period_cancel_charge
        created_at
        id
        name
        no_show_cancel_charge
        period_cancel_without_charge
        updated_at
      }
    }
  }
}
`;

const GET_ONE_ROOM_TYPES = gql`
{
  type
  updated_at
  name
  max_occupancy
  max_children
  max_adults
  id
  status
  e_room_type_status {
    id
    comment
  }
  e_room_type {
    comment
    id
  }
  room_type_beds {
    id
    length
    width
    room_type_id
    qty
    mattress_type
    bed_size
  }
  rooms (order_by: {name: asc}) {
    created_at
    floor
    id
    name
    e_room_status {
      comment
      id
    }
    e_room_clean_status {
      comment
      id
    }
    room_type_id
    status
    clean_status
    updated_at
  }
  area
  created_at
  default_occupancy
  description,
  room_facilities,
  bathroom_facilities,
  view_facilities,
  bedroom_facilities,
  entertainment_facilities,
  kitchen_facilities,
  outdoor_facilities,
  floor_facilities,
  images_url,
  room_type_rates {
    created_at
    id
    price
    room_rate_id
    room_type_id
    updated_at
    room_rate {
      cancellation_policy_id
      created_at
      id
      updated_at
      meal_id
      name
      status
      meal {
        created_at
        id
        name
        price
        service_charge
        updated_at
      }
      cancellation_policy {
        after_period_cancel_charge
        created_at
        id
        name
        no_show_cancel_charge
        period_cancel_without_charge
        updated_at
      }
    }
  }
}
`;

export{GET_LIST_ROOM_TYPES, GET_ONE_ROOM_TYPES}
