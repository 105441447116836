import gql from 'graphql-tag';

const GET_LIST_ROOMS = gql`
{
 
  created_at
  e_room_status {
    comment
    id
  }
  e_room_clean_status {
    comment
    id
  }
  floor
  id
  name
  room_type {
    area
    bathroom_facilities
    bedroom_facilities
    created_at
    default_occupancy
    description
    e_room_type {
      comment
      id
    }
    entertainment_facilities
    floor_facilities
    id
    kitchen_facilities
    max_adults
    max_children
    max_occupancy
    name
    outdoor_facilities
    room_facilities
    room_type_beds {
      width
      updated_at
      room_type_id
      qty
      mattress_type
      bed_size
      length
      id
      e_mattress_type {
        comment
        id
      }
      e_bed_size {
        comment
        id
      }
      created_at
    }
    type
    updated_at
    view_facilities
  }
  room_type_id
  status
  clean_status
  updated_at
}
`;

export{GET_LIST_ROOMS}
